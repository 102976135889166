<template>
    <div @click="satosaLogin()" class="d-flex sa-button" role="button" e2e-signin-seamless>
        <div class="sa-button-logo-wrap">
            <img src="https://service.seamlessaccess.org/sa-white.svg" class="sa-button-logo" alt="Seamless Access Logo" />
        </div>
        <div class="d-flex align-items-center sa-button-text text-truncate">
            <div class="text-truncate">
                <slot>Access through your institution</slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SeamlessAccessButton',
    props: {
        redirect: String
    },
    methods: {
        satosaLogin() {
            this.$auth.loginWithRedirect({
                connection: process.env.VUE_APP_AUTH0_SATOSA_CONNECTION,
                appState: { targetUrl: this.redirect }
            })
        }
    }
}
</script>

<style scoped>
/* DEFAULT */

/** Flexbox properties that keep button text centered horizontally and vertically. */

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

/** Text truncation that keeps the button fully responsive. */

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/** Button styling that utilizes Libre Franlin Google Font. */

.sa-button {
    cursor: pointer;
    background-color: var(--v-primary-base);
    border-radius: 5px;
    padding: 9px;
}

.sa-button-logo-wrap {
    text-align: center;
    width: 50px;
    height: 100%;
    padding: 5px 5px 5px 0;
}

.sa-button-logo {
    width: 30px;
    vertical-align: middle;
}

.sa-button-text {
    padding-left: 10px;
    text-align: center;
    width: 85%;
    color: #fff;
}

.sa-button-text-primary {
    font-size: 14px;
    font-weight: 700;
}

/** Styling of the "Add or change institution" text under the button. */

.sa-access-text {
    cursor: pointer;
    text-align: center;
    width: 100%;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.46;
    color: var(--v-primary-base);
}

.sa-cta-access,
.sa-cta-plus {
    display: inline;
    padding: 0 3px;
}

.sa-cta-plus > img {
    height: 13px;
}
</style>
